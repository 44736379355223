import React from "react"
import { Helmet } from "react-helmet"
import ShareImage from "../../../static/images/share_image.jpg"
import FavIcon32 from "../../favicon-32x32.png"
import FavIcon16 from "../../favicon-16x16.png"

interface Props {
  bodyClassname?: string
  description: string
  keywords: string
  title: string
  url: string
}

export default ({
  bodyClassname,
  description,
  keywords,
  title,
  url,
}: Props) => {
  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta
        property="og:site_name"
        content="Choosetap | Be a refiller not a landfiller"
      />
      <meta property="og:url" content={`${url}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ShareImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />

      <link
        rel="apple-touch-icon-precomposed"
        sizes="180x180"
        href="apple-touch-icon-180x180.png"
      />
      <link rel="icon" type="image/png" href={FavIcon32} sizes="32x32" />
      <link rel="icon" type="image/png" href={FavIcon16} sizes="16x16" />
      <title>{title}</title>
      <link rel="canonical" href={`${url}`} />
      <body className={bodyClassname} />
    </Helmet>
  )
}
